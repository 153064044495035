@charset "UTF-8";
@import "ap_preaset";

/*****************
*** common.css ***
*****************/

.ja .fltConVtc .middle .dateWrapper .slctCon {
	top: toRem(-309);
}
.ja .fltConVtc .middle .passengers .slctCon{
	top: toRem(-362);
}
@include rwd('ul'){
	.ja .fltConVtc .middle .dateWrapper .slctCon {
		top: toRem(-281);
	}
	.ja .fltConVtc .middle .passengers .slctCon{
		top: toRem(-324);
	}
}
@include rwd('xl'){
	.ja .fltConVtc .middle .dateWrapper .slctCon {
		top: toRem(-256);
	}
	.ja .fltConVtc .middle .passengers .slctCon{
		top: toRem(-298);
	}
}
@include rwd('lg'){
	.ja .fltConVtc .middle .passengers .slctCon{
		top: toRem(-298);
	}
}

@include rwd('md'){
	.ja .fltConVtc .middle .passengers .slctCon{
		top: toRem(-294);
	}
}
@include rwd('sm'){
	.ja .fltConVtc .middle .passengers .slctCon{
		top: toRem(0);
	}
}


/* inputWrap */
@media only screen and (max-width: 1199px) {
	.ja .inputWrap .row.euWarp .fwB:first-child {
		width: calc(100% - #{toRem(220)});
	}
}
@include rwd('sm'){
	.ja .inputWrap .row.euWarp .fwB:first-child {
		width: 100%;
	}
}
@media only screen and (max-width: 360px) {
	.ja .inputWrap .row.pntClct {
		height: toRem(110);
		margin: toRem(20) 0 toRem(10) 0;
	}
	.ja .inputWrap .row.pntClct .label {
		margin-bottom: toRem(10);
	}
	.ja .inputWrap .row.pntClct .value {
		display: block;
	}
	.ja .inputWrap .row.pntClct .value .rdoWrap .rdoBox {
		padding: toRem(5) 0 toRem(5) toRem(25);
	}
}

/* infoInputWrap */
.ja .infoInput .inputWrap {
	flex: 1 1 auto;
}

@media only screen and (max-width: 999px) {
	.ja .infoInput .infoTtl {
		flex-basis: auto;
	}
}

/* sendButton */
@media only screen and (max-width: 439px) {
	.ja .btnBox.flex .sendButton {
		margin-top: toRem(40);
	}
}


/*****************
*** style.css ***
*****************/

/* vertical */
.ja .fltConVtc .imgVtcBg {
	top: toRem(55);
}
.ja .fltConVtc h3 {
	font-size: toRem(26);
}
.ja .fltConVtc .imgVtcBg {
	top: toRem(45);
}
.ja .fltConVtc .imgVtcBg img {
	width: toRem(70);
}
@include rwd('lg'){
	.ja .fltConVtc h3 {
		font-size: toRem(20);
	}
	.ja .fltConVtc .imgVtcBg {
		top: toRem(35);
	}
	.ja .fltConVtc .imgVtcBg img {
		width: toRem(60);
	}
}

//@media only screen and (max-width: 479px) {
//	.ja .fltConVtc .imgVtcBg {
//		display: none;
//	}
//}
@include rwd('xl'){
	.ja .fltConVtc h3 {
		font-size: toRem(20);
		margin-bottom:1rem;
	}
	.ja .fltConVtc .bottom{
		margin-top:1rem;
	}
}
@include rwd('md'){
	.ja .fltConVtc h3 {
		font-size: toRem(18);
	}
}

//@include rwd('xs'){
//	.ja .fltConVtc h3 {
//		font-size: toRem(18);
//	}
//}

//@media only screen and (max-width: 375px) {
//	.ja .fltConVtc h3 {
//		font-size: toRem(20);
//	}
//}

/* 왕복,편도 버튼 */
@include rwd('sm'){
	.ja .tripType button{
		padding:0 0.5rem;
	}
	.ja .tripType button.on:first-child{
		padding-left:0.5rem;
	}

}

@include rwd('xl'){
	.ja .tripType {
		display: block;
	}
	.ja .toggleWrap.flex {
		width: 100%;
		margin-top: toRem(10);
		display: block;
		text-align: right;
	}
	.ja .tripType .toggleWrap .container {
		width: auto;
	}
}

/* 예매 */
@media only screen and (max-width: 479px) {
	.ja .rcpConWrap dl {
		display: block;
	}
	.ja .rcpConWrap dl dd {
		text-align: right;
	}
}


/* footer */
.ja footer .right .newsWrap input {
	width: toRem(360);
}
.ja footer .right .newsWrap span {
	top: toRem(9);
}
.ja footer .right {
	margin-left: 4.25rem;
}

@include rwd('lg'){
	.ja footer .wrapper {
		padding: toRem(50) toRem(25);
	}
	.ja footer .wrapper .top {
		padding-bottom: toRem(40);
		display: block;
	}
	.ja footer .left {
		width: 100%;
	}
	.ja footer .right {
		margin-left: 0;
		margin-top: toRem(60);
		display: flex;
		justify-content: space-between;
	}
	.ja footer .right .support {
		position: static;
	}
}
@include rwd('sm'){
	.ja footer .right {
		display: block;
	}
}

/* 부가서비스,결제 공통 */
.ja .payReceipt .expWrapper .itemsWrapper .row:nth-child(4) {
	display: block;
}
.ja .payReceipt .expWrapper .row .value {
	text-align: right;
}
@include rwd('md'){
	.ja .contWrapper .left .contents.flex {
		display: block;
	}
	.ja .contWrapper .left .contents.flex .link.bd {
		margin-top: toRem(20);
	}
	.ja .slctBox button.val .slct {
		//width: calc(100% - #{toRem(10)});
	}
	.ja .fghPay .contWrapper .left .inputWrap {
		padding-bottom: toRem(52);
	}
	.ja .fghPay .inputWrap .row.couponCode {
		display: block;
	}
	.ja .fghPay .inputWrap .row.couponCode .value {
		padding-left: 0;
	}
	.ja .fghPay .inputWrap .row.couponCode .value input {
		margin-top: toRem(5);
	}
}
@include rwd('sm'){
	.ja .fghPay .contWrapper .left .inputWrap {
		padding-bottom: 0;
	}
	.ja .payReceipt .expWrapper .itemsWrapper .row:nth-child(4) {
		display: flex;
	}
	.ja .ssrWrapper {
		padding: toRem(16);
	}
	.ja .ssrlft {
		margin-right: toRem(10);
	}

	.ja .ssrrgt .titWrapper .subtxt {
		word-break: break-word;
	}

}

@media only screen and (max-width: 360px) {
	.ja .payReceipt .expWrapper .row {
		display: block;
	}
	.ja .payReceipt .expWrapper .sumsWrapper .row .value {
		text-align: right;
	}
}

/* 결제 전 확인 */
@include rwd('lg'){
	.ja dl.info {
		margin-right: toRem(40);
	}
}

/* 결제완료 */
@include rwd('lg'){
	.ja .fltCon::after {
		display: none;
	}
	.ja .slctBox button.val .arNm {
		width: 100%;
	}
}

/* 예매>결제방법 flight_06pay*/
@media only screen and (max-width: 479px) {
	.ja .fghPay .inputWrap .row .label {
		width: 45%;
	}
}

/* cabinClassOneway */
@media only screen and (max-width: 479px) {
	.ja .classTicket .ticketBox .classType {
		display: block;
	}
	.ja .classTicket .ticketBox .classType .classRdo {
		display: flex;
		align-items: center;
	}
	.ja .classTicket .ticketBox .price {
		text-align: right;
	}
}

/* 예약 상세 */
@include rwd('lg'){
	.ja	.boardingCon .infContent >div:nth-child(2) {
		padding-left: toRem(40);
	}
}
@include rwd('sm'){
	.ja .boardingCon {
		margin-bottom: toRem(10);
	}
	.ja .boardingCon .sendButton {
		top: toRem(-4);
	}
	.ja .boardingCon .infContent >div:nth-child(2) {
		padding-left: 0;
	}
}

/* 버튼 */
.ja .lb02IW .value.passFlex{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.ja .lb02IW .value.passFlex .slctBox {
	width: 33.33%;
}
.ja .lb02IW .value.passFlex .divideLine {
	width: toRem(1);
    height: toRem(18);
    margin: 0 toRem(10);
    display: block;
    background: $ap-text-gray-4;
}


/*****************
*** style2.scss ***
*****************/

/* 운임안내 */
.ja .fareWrap .classArea .iconBox p {
	width: toRem(150);
}
@include rwd('sm'){
	.ja .fareWrap .classArea .illustBox {
		align-items: flex-start;
	}
	.ja .fareWrap .classArea .iconBox {
		min-height: toRem(220);
	}
}

/* 온라인 체크인 */
.ja .onChkBot .BtnWrap {
	width: toRem(200);
}
@include rwd('md'){
	.ja .onChkBot .infosWrap .info + .info {
		padding-left: toRem(40);
	}
}
@include rwd('sm'){
	.ja .onChkTop {
		display: block;
	}
	.ja .textLinkBtn {
		margin-left: auto;
	}
	.ja .onChkBot .infosWrap .info + .info {
		padding-left: 0;
	}
}


/* 운송제한물품 */
.ja .tbDiv ul li .conBox.hGtlw {
	height: toRem(221);
}
.ja .tbDiv ul li .conBox.hGtmd {
	height: toRem(337);
}

@include rwd('lg'){
	.ja .tbDiv ul li .conBox {
		height: toRem(280);
	}
	.ja .tbDiv ul.lic2 li .conBox,
	.ja .tbDiv ul li .conBox.hGtmd {
		height: toRem(520);
	}
	.ja .tbDiv ul.lb5m li .conBox {
		height: toRem(560);
	}
}
@include rwd('md'){
	.ja .tbDiv ul li .conBox {
		height: toRem(200);
	}
	.ja .tbDiv ul.lic2 li .conBox,
	.ja .tbDiv ul li .conBox.hGtmd {
		height: toRem(300);
	}
	.ja .tbDiv ul.lic1 li .conBox {
		height: toRem(120);
	}
	.ja .tbDiv ul.lb5m li .conBox {
		height: toRem(280);
	}
	.ja .tbDiv ul.lb5m li.last .conBox {
		height: toRem(100);
	}
}
@include rwd('sm'){
	.ja .tbDiv ul li .conBox {
		height: toRem(260);
	}
}
@include rwd('xs'){
	.ja .tbDiv ul.lic2 li .conBox,
	.ja .tbDiv ul li .conBox.hGtmd {
		height: toRem(420);
	}
	.ja .tbDiv ul.lb5m li .conBox {
		height: toRem(340);
	}
}

@media only screen and (max-width: 359px) {
	.ja .tbDiv ul li .conBox {
		height: toRem(320);
	}
	.ja .tbDiv ul.lic1 li .conBox {
		height: toRem(160);
	}
	.ja .tbDiv ul.lb5m li .conBox {
		height: toRem(370);
	}
}
